import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';

const Whitepaper = () => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const dummyContent = `
# KimchiSauce White Paper
- Published on 2025-01-27
- Last Updated on 2025-01-27

## 1. Introduction

### 1.1 The Kimchi Premium
The **“kimchi premium”** refers to the price discrepancy observed between cryptocurrencies traded on South Korean exchanges and those traded on non-Korean (global) exchanges. During periods of high local demand or restrictive capital movement, digital assets like Bitcoin can trade at a noticeable premium in South Korea compared to other regions.

### 1.2 Bridging Remittances and the Kimchi Premium
Remittances represent a **multi-billion dollar** global industry; individuals working abroad regularly send money back home. However, traditional remittance channels are often plagued by:

- **High fees** imposed by intermediaries (banks, money-transfer operators, etc.).
- **Slow settlement times**, sometimes taking days to reach the recipient.
- **Complex regulatory requirements** that can differ significantly across borders.

**KimchiSauce** integrates these two facets—remittances and the kimchi premium—into a single protocol. By **tokenizing** the arbitrage process (buying crypto at a lower price abroad and selling at a higher price in Korea), the project seeks to **offset or reduce** remittance fees, ultimately passing on cost savings to users sending money across borders.

### 1.3 Purpose and Vision
The primary goals of KimchiSauce are:

1. **Capture** arbitrage opportunities arising from the kimchi premium.  
2. **Enable lower-cost, faster remittances** by using a portion of the arbitrage gains to subsidize transaction fees.  
3. **Empower** a decentralized, community-governed solution that benefits both retail and institutional participants.

---

## 2. Market Landscape

### 2.1 South Korea’s Role in Global Crypto
South Korea remains one of the most **crypto-active** countries, often driving local prices above global averages. This situation sets the stage for consistent market inefficiencies—a prime target for arbitrage strategies.

### 2.2 Remittance Industry and Pain Points
Traditional remittances typically involve:

- **Multiple intermediaries**, each taking a cut.  
- **Opaque pricing** structures (hidden fees, unfavorable FX rates).  
- **Prolonged wait times**, especially if multiple financial networks are involved.

In contrast, **cryptocurrency-based transfers** can be near-instantaneous, transparent, and cheaper—**especially** when combined with an arbitrage system that can offset operational costs.

### 2.3 The Kimchi Premium as a Lever
By deploying a **protocol-driven arbitrage strategy** that exploits price differentials, KimchiSauce can potentially:

- **Realize profit** each time it buys crypto low (on global exchanges) and sells high (on Korean exchanges).  
- **Use part of that profit** to **subsidize** or **fully cover** remittance fees for users.  
- **Attract new liquidity** and increase trading volumes, further strengthening the protocol’s price stability and network effect.

---

## 3. KimchiSauce Protocol Overview

### 3.1 Architecture
KimchiSauce comprises several critical components:

1. **Data Oracles**  
   - Aggregates prices from multiple Korean exchanges (e.g., Bithumb, Upbit) and global exchanges (e.g., Binance, Coinbase).  
   - Feeds this data to the on-chain **kimchi index** to track real-time price discrepancies.

2. **Liquidity Pools**  
   - Accept deposits in stablecoins (e.g., USDC, USDT) or other crypto assets.  
   - Provide the capital needed for **both** arbitrage operations **and** remittance facilitation.

3. **Arbitrage Execution Engine**  
   - Executes cross-exchange trades when profitable.  
   - Automates transfers, sell orders, and FX conversions from KRW back to a stable asset (or vice versa).

4. **Remittance Module**  
   - Integrates with payment gateways or local fiat off-ramps.  
   - Allows users to send funds (in crypto or local currency) to recipients in South Korea (or abroad) at **reduced fees**, leveraging the protocol’s arbitrage profits as a subsidy.

5. **KimchiSauce Token (KST)**  
   - A governance and utility token, granting voting power and a share in the protocol’s revenue.  
   - Potentially grants **discounted remittance fees** or other benefits to holders or stakers.

### 3.2 User Workflows

1. **Arbitrage Provider**  
   - Deposits stablecoins or crypto into KimchiSauce liquidity pools, earning a portion of arbitrage returns.

2. **Remittance Sender**  
   - Submits a remittance transaction via KimchiSauce’s platform.  
   - The protocol executes any required crypto conversions, factoring in arbitrage gains.  
   - Recipient receives the funds at a **reduced fee** compared to traditional transfer services.

### 3.3 Value Proposition

- **For Arbitrage Providers**: Earn yield from persistent market inefficiencies.  
- **For Remittance Senders**: Lower fees and faster transaction times, particularly for corridors involving South Korea.  
- **For the Ecosystem**: A community-driven approach that aligns incentives between investors, traders, and everyday users who need cross-border transfers.

---

## 4. Capturing the Kimchi Premium (and Subsidizing Remittance Fees)

### 4.1 Premium Indexing
KimchiSauce’s oracles continuously monitor:

- **Global Exchange Prices**: Binance, Coinbase, etc.  
- **Korean Exchange Prices**: Bithumb, Upbit, etc.

This data stream updates an internal **kimchi index**, reflecting any positive (or negative) premium.

### 4.2 Automated Arbitrage for Profit
When the premium crosses a defined threshold:

1. **Buy** crypto (e.g., BTC) on the cheaper global exchange(s).  
2. **Transfer** the crypto to the Korean exchange.  
3. **Sell** the crypto for KRW at the higher local price.  
4. **Convert** KRW back to stablecoins or USD, bringing profits on-chain.

### 4.3 Fee Subsidy for Remittances
**A portion of these arbitrage profits** can be allocated to subsidize remittance fees:

- If total arbitrage profit is sufficiently high, the protocol can **reduce or waive** fees entirely for certain transactions.  
- Users benefit from a **transparent fee structure**, seeing real-time estimates of cost reductions based on the current kimchi index and liquidity.

### 4.4 Risk Management
To ensure stability:

- **Maximum Arbitrage Size**: Prevents large trades that might cause excessive slippage or invite regulatory scrutiny.  
- **Rate Limits**: Spreads out arbitrage operations over time to avoid flooding the market.  
- **Compliance Checks**: Works with regulated fiat on- and off-ramps to ensure alignment with capital control regulations.

---

## 5. Token Economics

### 5.1 KimchiSauce Token (KST)
An ERC-20 or equivalent token with the following features:

- **Governance**  
  - KST holders propose and vote on protocol updates (e.g., fee changes, new market expansions, adjusting subsidy percentages for remittance).  

- **Profit-Sharing Mechanism**  
  - A percentage of arbitrage profits is directed toward **KST buybacks** or distributed as staking rewards.

- **Reduced Remittance Fees**  
  - KST holders or stakers may receive **discounted or waived** fees on remittance transactions, incentivizing token adoption.

### 5.2 Supply and Distribution

- **Initial Token Supply**: Determined at launch, potentially deflationary if buybacks outpace new token issuance.  
- **Vesting**: Core team and strategic partners have locked tokens to align incentives.  
- **Public Sale or Liquidity Bootstrapping**: Possible distribution strategies to involve the community early on.

### 5.3 Fee Model

1. **Performance Fee**  
   - A share (e.g., 10–20%) of arbitrage profits is taken by the protocol’s treasury.  
   - Part of this is used to subsidize remittances, part is allocated to KST buybacks, part is reserved for protocol maintenance.

2. **Transaction Fee (Remittances)**  
   - Initially set below typical remittance fees charged by banks or money-transfer operators.  
   - Adjusted dynamically based on market conditions and arbitrage performance.

---

## 6. Governance

### 6.1 Decentralized DAO Structure
**KimchiSauce DAO** aims to enable KST holders to shape the protocol’s future. Through a robust on-chain voting process, the community can:

- **Add New Remittance Corridors** (e.g., USD <-> KRW, JPY <-> KRW, etc.)  
- **Adjust Arbitrage Parameters** (thresholds for capital deployments, risk controls)  
- **Allocate Treasury Funds** (developer bounties, marketing, further fee subsidies)

### 6.2 Governance Lifecycle

1. **Proposal Creation**: Any KST holder submits a proposal.  
2. **Community Deliberation**: Off-chain forums or social platforms gather feedback.  
3. **On-Chain Vote**: KST stakers vote, with results automatically tallied.  
4. **Implementation**: Successful proposals are executed via smart contracts or by the core development team.

---

## 7. Roadmap

1. **Phase I: Foundation & Token Launch**  
   - Token smart contracts audited and launched (KST).  
   - Initial liquidity provisioning for key stablecoins.  
   - Set up basic arbitrage logic for select global and Korean exchanges.

2. **Phase II: Remittance Module Integration**  
   - Deploy user-facing remittance portal (web and mobile).  
   - Onboard regulated local off-ramps in South Korea and initial test corridors.  
   - Launch pilot subsidy program to test fee reductions using arbitrage profits.

3. **Phase III: Governance & DAO Rollout**  
   - Launch of DAO governance contracts (proposal and voting system).  
   - Community-driven decisions on new corridors.  
   - Enhanced yield strategies with advanced arbitrage conditions.

4. **Phase IV: Multi-Chain & Wider DeFi Integrations**  
   - Bridge KST to other blockchains (e.g., BSC, Polygon) for broader accessibility.  
   - Integrate with DeFi lending, borrowing, and yield-optimization platforms.  
   - Explore expansions to capture other regional premiums (e.g., possible “yuan premium”).

5. **Phase V: Global Adoption & Remittance Expansion**  
   - Extend coverage to additional high-volume remittance corridors outside Korea.  
   - Deepen partnerships with fintech companies and establish new local compliance frameworks.  
   - Increase marketing and user education, driving mainstream adoption.

---

## 8. Risk Factors and Regulatory Considerations

### 8.1 Market Volatility and Liquidity
- **Rapid Price Fluctuations**: Premiums can evaporate quickly.  
- **Limited Liquidity**: Spreads, slippage, and exchange downtime can affect profitability.

### 8.2 Legal & Compliance
- **Cross-Border Regulations**: Countries impose varying restrictions on moving funds.  
- **Remittance Licensing**: Some jurisdictions require money transmitter licenses. KimchiSauce must partner or comply with local frameworks where applicable.

### 8.3 Smart Contract & Security
- **Contract Vulnerabilities**: Despite audits, unforeseen exploits can occur.  
- **Custodial Risk**: Certain liquidity or bridging solutions may rely on third-party custodians.

### 8.4 Governance Attack Vectors
- **Hostile Takeovers**: Accumulation of tokens by malicious actors could steer the protocol in a harmful direction.  
- **Low Voter Participation**: Could lead to suboptimal or non-representative decisions.

---

## 9. Conclusion
**KimchiSauce** stands at the intersection of **arbitrage** and **remittances**, offering a novel approach to reduce cross-border transaction costs by leveraging the kimchi premium. By combining:

- **A robust arbitrage engine** that generates consistent profits,  
- **A user-friendly remittance module** designed to pass on fee savings,  
- **A community-governed DAO framework** to steer the protocol’s evolution,

KimchiSauce aspires to be a **game-changer** for individuals and businesses seeking **faster, cheaper, and more transparent** ways to move money across borders.

We invite developers, traders, remittance companies, and global communities to **participate in and shape** the future of KimchiSauce. As the industry landscape evolves, we remain committed to **compliance**, **innovation**, and a **user-first** ethos.

---

## Appendix

### A. References
- Academic research on the kimchi premium and crypto arbitrage  
- Global remittance reports from the World Bank and IMF

### B. Glossary
- **Kimchi Premium**: Price discrepancy in crypto markets between South Korea and global exchanges.  
- **Remittance**: Cross-border money transfers typically sent by migrant workers to families in their home countries.  
- **DAO**: Decentralized Autonomous Organization, enabling token holders to vote on proposals and shape protocol governance.  
- **Arbitrage**: Buying and selling assets across different markets to profit from price differences.

### C. Disclaimer
This document does not constitute financial, legal, or investment advice. Participation in KimchiSauce involves risk, and participants should conduct **due diligence** and consult with licensed professionals. Regulatory complexities vary by region, and users are responsible for ensuring compliance with local laws.

---

### Next Steps
1. **Technical Documentation**  
   - Release in-depth specs on smart contract logic, remittance integrations, and bridging mechanisms.  

2. **Pilot Rollouts**  
   - Conduct limited-scale tests of the remittance module to measure fee savings and user experience.

3. **Community Engagement**  
   - Host AMA sessions, dev workshops, and partnerships to expand the KimchiSauce ecosystem.

---

**KimchiSauce** — “Stirring Up Global Remittances with the Kimchi Premium.”

    `;

    // Simulating fetch with dummy content for now
    setContent(dummyContent);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500">Failed to load whitepaper: {error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="prose prose-lg max-w-none prose-headings:text-gray-900 prose-p:text-gray-700">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whitepaper; 